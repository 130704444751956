import { createStore, combineReducers } from "redux";
import phonesReducer from "../reducers/phonesReducer";
import ayurvedicReducer from "../reducers/ayurvedicReducer";
import spritualReducer from "../reducers/spritualReducer";
import physioReducer from "../reducers/physioReducer";
import homeopathyReducer from "../reducers/homeopathyReducer";
import fashionsReducer from "../reducers/fashionsReducer";
import toysReducer from "../reducers/toysReducer";
import productsReducer from "../reducers/productsReducer";
import booksReducer from "../reducers/booksReducer";
import clothesReducer from "../reducers/clothesReducer";
import paymentFormReducer from "../reducers/paymentFormReducer";
import ordersReducer from "../reducers/ordersReducer";
import filtersReducer from "../reducers/filtersReducer";
import categoriesReducer from "../reducers/categoriesReducer";

const configureStore = () => {
  const store = createStore(
    combineReducers({
      phonesReducer,
      ayurvedicReducer,
      spritualReducer,
      physioReducer,
      homeopathyReducer,
      fashionsReducer,
      clothesReducer,
      productsReducer,
      toysReducer,
      booksReducer,
      filters: filtersReducer,
      categories: categoriesReducer,
      paymentInfo: paymentFormReducer,
      orders: ordersReducer,
    }),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  return store;
};

export default configureStore;
