import React from "react";
import { Dropdown, Container } from "react-bootstrap";
import {SectionTitle} from "../Common/SectionTitle";
import { AboutParagraph } from "../Common/AboutParagraph";
import { WhyChooseSection } from "../Common/WhyChooseSection";

export const AboutUs = () => {
  return (
    <React.Fragment>
              <div className="section-top-padding"></div>
        <SectionTitle sectionheading="Who We Are" />
        <Container>
          <AboutParagraph />
        </Container>
        
        <WhyChooseSection isTitle={false}/>

    </React.Fragment>
  );
};
