import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Home } from "../components/Home/Home";
import { ProductsList } from "../components/ProductsList/ProductsList";
import NotFound from "./../components/NotFound/NotFound";
import Phone from "./../components/Phone/Phone";
import CartPage from "../components/CartPage/CartPage";
import AddPhoneForm from "../components/AddPhoneForm/AddPhoneForm";
import { Contacts } from "../components/Contacts/Contacts";
import { AboutUs } from "../components/AboutUs/AboutUs";
import { Blog } from "../components/Blog/Blog";
import { Technology } from "../components/Technologies/Technology";
import { Services } from "../components/Services/Services";
import { AddCategoryComp } from "../components/AddCategoryComp/AddCategoryComp";
import { AddBrand } from "../components/AddBrand/AddBrand";
import { AddProduct } from "../components/AddProduct/AddProduct";
import { NavbarCategory } from "../components/ProductsList/NavbarCategory";
import { LoginForm } from "../components/LoginForm/LoginForm";
import { NewAccount } from "../components/LoginForm/NewAccount";
import { ProductDetails } from "../components/ProductDetails/ProductDetails";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact={true} />
        <Route path="/products" component={ProductsList} />
        <Route path="/cart" component={CartPage} />
        <Route path="/phones/:id/:category" component={Phone} exact={true} />
        <Route path="/add-phone-form/:category" component={AddPhoneForm} />
        <Route path="/home" component={Home} />
        <Route path="/contacts" component={Contacts} />
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/productdetail" component={ProductDetails} />
        <Route path="/blog" component={Blog} />
        <Route path="/technologies" component={Technology} />
        <Route path="/services" component={Services} />
        <Route path="/productlist" component={ProductsList} />
        <Route path="/AddCategoryComp" component={AddCategoryComp} />
        <Route path="/AddBrand" component={AddBrand} />
        <Route path="/AddProduct" component={AddProduct} />
        <Route path="/NavbarCategory" component={NavbarCategory} />
        <Route path="/LoginForm" component={LoginForm} />
        <Route path="/NewAccount" component={NewAccount} />
        {/* <Route component={NotFound} /> */}
      </Switch>
    </Router>
  );
};

export default Routes;
