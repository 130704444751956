import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./Phone.scss";
import CartBox from "./../CartBox/CartBox";
import AddToCart from "./../AddToCart/AddToCart";
import { Link } from "react-router-dom";
import { getAllPhones } from "../../state/selectors/phones";
import { getAllFashions } from "../../state/selectors/fashions";
import { getAllClothes } from "../../state/selectors/clothes";
import { getAllToys } from "../../state/selectors/toys";
import { getAllBooks } from "../../state/selectors/books";
import { getAllAyurvedic } from "../../state/selectors/ayurvedic";
import { getAllSpritual } from "../../state/selectors/spritual";
import { getAllPhysio } from "../../state/selectors/physio";

const Phone = ({ match }) => {
  const category = match.params.category;
  const [phoneId, setPhoneId] = useState(null);
  const footwaresData = useSelector(getAllPhones);
  const fashionsData = useSelector(getAllFashions);
  const clothesData = useSelector(getAllClothes);
  const toysData = useSelector(getAllToys);
  const booksData = useSelector(getAllBooks);
  const ayurvedicData = useSelector(getAllAyurvedic);
  const spritualData = useSelector(getAllSpritual);
  const physioData = useSelector(getAllPhysio);
  const [imgDisplay, setImgDisplay] = useState("");
  const manageCategoryChangeData = () => {
    const fashionsDataLocal = fashionsData.find(
      ({ id }) => id === match.params.id
    );
    const footwaresDataLocal = footwaresData.find(
      ({ id }) => id === match.params.id
    );
    const clothesDataLocal = clothesData.find(
      ({ id }) => id === match.params.id
    );
    const ayurvedicDataLocal = ayurvedicData.find(
      ({ id }) => id === match.params.id
    );
    const spritualDataLocal = spritualData.find(
      ({ id }) => id === match.params.id
    );
    const physioDataLocal = physioData.find(({ id }) => id === match.params.id);
    category === "ayurvedic" && setPhoneId(ayurvedicDataLocal);
    category === "spritual" && setPhoneId(spritualDataLocal);
    category === "physio" && setPhoneId(physioDataLocal);
  };

  useEffect(() => {
    manageCategoryChangeData();
  }, []);

  useEffect(() => {
    phoneId && setImgDisplay(`/products/${category}/${phoneId.picture}.jpeg`);
  }, [phoneId]);

  return (
    <React.Fragment>
      {phoneId !== null && (
        <div className="phone-details">
          <div className="phone-details--grid">
            <div className="phone">
              <div className="phone--box">
                <img src={imgDisplay} alt="phone" />
              </div>
              <div className="titleProduct">
                <h3>
                  {phoneId.brand} {phoneId.name}
                </h3>
                <h3>${phoneId.price}</h3>
              </div>
              <div className="phone--description">
                <p>{phoneId.description}</p>
              </div>
            </div>

            <div className="phone-stats">
              <h1>{match.params.category.toUpperCase()} Details</h1>
              <div className="stat">
                <h2>Brand</h2>
                <p>{phoneId.brand}</p>
              </div>
              {Object.entries(phoneId.stats).map(([key, value]) => {
                return (
                  <div className="stat" key={key}>
                    <h2>{key}</h2>
                    <p>{value.toString()}</p>
                  </div>
                );
              })}
            </div>

            <div className="phone-sidebar">
              <CartBox />
              <div className="cart-info">
                <h3>
                  {phoneId.brand} {phoneId.name} ${phoneId.price}
                </h3>
              </div>
              <AddToCart phone={phoneId} />
              <Link to="/" className="back-to-store">
                Back to store
              </Link>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Phone;
