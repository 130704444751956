import React from "react";
import { SectionTitle } from "../Common/SectionTitle";
import { Container } from "react-bootstrap";
import { CardServices } from "../Services/CardServices";

export const Services = () => {
  return (
    <React.Fragment>
      <div className="section-top-padding"></div>
      <SectionTitle sectionheading="Services & Specialty Products" />
      <Container>
        <CardServices descimg="AltViewServices.jpg" />
      </Container>
    </React.Fragment>
  );
};
