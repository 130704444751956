import uuid from "uuid/v4";

const spritualDefaultState = {
  
  spritual: [
    {
      id: uuid(),
      brand: "Healing ",
      name: `Reiki Spiritual Healing Treatment stock`,
      price: 130,
      picture: "20",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Healing ",
      name: `energy healing techniques`,
      price: 120,
      picture: "21",
      description:
        `Reiki Healing The word Reiki is made from two Japanese words called- 'Rei' meaning "God’s wisdom" and Ki meaning “energy”`,
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: " Tention",
      name: `Addressing Spiritual Issues`,
      price: 330,
      picture: "22",
      description:
        `Addressing Spiritual Issues Within Treatment for “Psychosis” and “Bipolar Disorder”`,
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Pendulum ",
      name: `How to use a Pendulum for balancing the body energy`,
      price: 330,
      picture: "23",
      description:
        `Addressing Spiritual Issues Within Treatment for “Psychosis” and “Bipolar Disorder”`,
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Pendulum ",
      name: `crystal pendulum Stock`,
      price: 330,
      picture: "24",
      description:
        `Addressing Spiritual Issues Within Treatment for “Psychosis” and “Bipolar Disorder”`,
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
  ],
  cart: [],
  total: 0,
};

const spritualReducer = (state = spritualDefaultState, action) => {
  switch (action.type) {
    case "ADD_NEW_PHONE":
      return {
        ...state,
        spritual: [...state.spritual, action.phone],
      };
    case "ADD_TO_CART":
      const addedPhone = state.spritual.find((phone) => action.id === phone.id);
      const existingPhone = state.cart.find(
        (existingPhone) => action.id === existingPhone.id
      );

      if (existingPhone) {
        addedPhone.quantity += 1;
        return {
          ...state,
          total: state.total + addedPhone.price,
        };
      } else {
        addedPhone.quantity = 1;
        const newTotal = state.total + addedPhone.price;
        return {
          ...state,
          cart: [...state.cart, addedPhone],
          total: newTotal,
        };
      }
    case "REMOVE_FROM_CART":
      const phoneToRemove = state.cart.find((phone) => action.id === phone.id);
      const removePhone = state.cart.filter((phone) => action.id !== phone.id);

      const newTotal =
        state.total - phoneToRemove.price * phoneToRemove.quantity;
      return {
        ...state,
        cart: removePhone,
        total: newTotal,
      };
    case "DECREMENT":
      const phone = state.cart.find((phone) => action.id === phone.id);

      if (phone.quantity > 1) {
        phone.quantity -= 1;
        const newTotal = state.total - phone.price;
        return {
          ...state,
          total: newTotal,
        };
      } else {
        return state;
      }
    case "CLEAR_CART":
      return {
        ...state,
        cart: [],
        total: 0,
      };
    default:
      return state;
  }
};

export default spritualReducer;
