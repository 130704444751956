import React from "react";
import imgLogo from "../../img/pickupbizzlogo.jpg";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Form, FormControl, Button } from "react-bootstrap";
import brandLogo from "../../img/allview-logo.png";

export const NavbarList = () => {
  return (
    <React.Fragment>
      <Navbar sticky="top" collapseOnSelect expand="lg" variant="light" className="nav-bg">
        <Container>
          <Navbar.Brand href="/home" className="brandname">
            <img className="brand-logo" src={brandLogo} alt="ofi films" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link className="nav-element" href="/productlist">
                Our Offerings
              </Nav.Link>
              <Nav.Link className="nav-element" href="/services">
                Services
              </Nav.Link>
              <Nav.Link className="nav-element" href="/aboutus">
                About Us
              </Nav.Link>
              <Nav.Link className="nav-element" href="/contacts">
                Contact Us
              </Nav.Link>
            </Nav>
            <Nav className="ml-auto">
              <Nav.Link className="companyname" href="/home">
                ALT VIEW RESEARCH
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};
