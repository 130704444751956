import axios from "axios";
import logger from "./logService";
//import { toast } from "react-toastify";

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
        console.log("Internet network issue!!");
       }
       else{
        console.log("Not found -- error.response");
       }
 });

// Add a response interceptor
// axios.interceptors.response.use(function (response) {
//   console.log(response);
//   // Do something with response data
//   if(response!=="undefined")
//     return response;
//   else
//     console.log('Not found response');
// }, function (error) {
//   // Do something with response error
//   console.log(error.response);
//   return Promise.reject(error);
// });

// axios.interceptors.response.use(null, error => {
//   const expectedError =
//     error.response &&
//     error.response.status >= 400 &&
//     error.response.status < 500;

//   if (!expectedError) {
//     logger.log(error);
//     //toast.error("An unexpected error occurrred.");
//   }

//   return Promise.reject(error);
// });

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete
};
