import uuid from "uuid/v4";

const toysDefaultState = {
  toys: [
    {
      id: uuid(),
      brand: "Raymond",
      name: `
      Can Building Toys for Girls Improve Math and Tech Skills`,
      price: 13,
      picture: "7",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Raymond",
      name: `
      Walmart
      Ride on Toy, 3 Wheel Motorcycle Trike for Kids by Rockin' R`,
      price: 350,
      picture: "9",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Raymond",
      name: `
      Toys & Games Ride On Kids Toy Bike Motor Bike Boys Girl`,
      price: 300,
      picture: "10",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "bugatti",
      name: `Baby Sofa Seat or Rocking Chair for Kids`,
      price: 220,
      picture: "1",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "playskool toys",
      name: ` Best Educational Toys for Kids `,
      price: 690,
      picture: "4",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Lovely Too",
      name: `
      Kids Makeup Toys Girls Games Baby Cosmetics Pretend`,
      price: 1000,
      picture: "5",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Generic",
      name: `
      Learning Baby Toys
      Baby Tablet Educational Toys Girls Toy For 1-6`,
      price: 500,
      picture: "6",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "PUMA",
      name: `SRECAP Yamaha R1 Plastic Bike with Rechargeable Battery Operated Ride-on for Kids (Red , 2-6 Years)`,
      price: 770,
      picture: "8",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Woodland",
      name: `
      HolidayLife Musical Toys Kids Baby Roll Drum`,
      price: 400,
      picture: "11",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Woodland",
      name: `Musical Drum and Keyboard for Baby,`,
      price: 100,
      picture: "12",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Woodland",
      name: `Paw Patrol MARSHALL CARRY CASE MUSICAL INSTRUMENT`,
      price: 150,
      picture: "13",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Bojo",
      name: `
      Kids Toy Box Subscription 2-12 yrs`,
      price: 550,
      picture: "2",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Bead Game",
      name: `
      
      Baby Toys Children Kids Baby Colourful Wooden Playing Funny Toy Mini Around Beads Educational Toy
      `,
      price: 300,
      picture: "3",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
  ],
  cart: [],
  total: 0,
};

const toysReducer = (state = toysDefaultState, action) => {
  switch (action.type) {
    case "ADD_NEW_PHONE":
      return {
        ...state,
        toys: [...state.toys, action.toy],
      };
    case "ADD_TO_CART":
      const addedFasion = state.toys.find((toy) => action.id === toy.id);
      const existingFasion = state.cart.find(
        (existingFasion) => action.id === existingFasion.id
      );

      if (existingFasion) {
        addedFasion.quantity += 1;
        return {
          ...state,
          total: state.total + addedFasion.price,
        };
      } else {
        addedFasion.quantity = 1;
        const newTotal = state.total + addedFasion.price;
        return {
          ...state,
          cart: [...state.cart, addedFasion],
          total: newTotal,
        };
      }
    case "REMOVE_FROM_CART":
      const toyToRemove = state.cart.find((toy) => action.id === toy.id);
      const removeFasion = state.cart.filter((toy) => action.id !== toy.id);

      const newTotal = state.total - toyToRemove.price * toyToRemove.quantity;
      return {
        ...state,
        cart: removeFasion,
        total: newTotal,
      };
    case "DECREMENT":
      const toy = state.cart.find((toy) => action.id === toy.id);

      if (toy.quantity > 1) {
        toy.quantity -= 1;
        const newTotal = state.total - toy.price;
        return {
          ...state,
          total: newTotal,
        };
      } else {
        return state;
      }
    case "CLEAR_CART":
      return {
        ...state,
        cart: [],
        total: 0,
      };
    default:
      return state;
  }
};

export default toysReducer;
