import uuid from "uuid/v4";

const physioDefaultState = {
  physio: [
    {
      id: uuid(),
      brand: "Electrotherapy",
      name: `kney pain`,
      price: 130,
      picture: "20",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Massage  ",
      name: `Joint pain`,
      price: 120,
      picture: "21",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "stroke",
      name: `Effective Physiotherapy for stroke patients `,
      price: 330,
      picture: "22",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
        brand: "Electrotherapy",
        name: ` Portable Electrotherapy`,
        price: 130,
        picture: "24",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
        stats: {
          size: "6.26 inches, 97.8 cm2 ",
          resolution: "720 x 1520 pixels, 19:9 ratio",
          GPU: "Mali-G76 MP12 - EMEA",
          CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
          camera: "12+ 2MP rear camera",
          battery: "1400 mAh",
        },
      },
  {
    id: uuid(),
      brand: "Electrotherapy",
      name: ` CUM ULTRASOUND 1& 3 MHZ COMBINATION THERAPY`,
      price: 130,
      picture: "25",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
  ],
  cart: [],
  total: 0,
};

const physioReducer = (state = physioDefaultState, action) => {
  switch (action.type) {
    case "ADD_NEW_PHONE":
      return {
        ...state,
        physio: [...state.physio, action.phone],
      };
    case "ADD_TO_CART":
      const addedPhone = state.physio.find((phone) => action.id === phone.id);
      const existingPhone = state.cart.find(
        (existingPhone) => action.id === existingPhone.id
      );

      if (existingPhone) {
        addedPhone.quantity += 1;
        return {
          ...state,
          total: state.total + addedPhone.price,
        };
      } else {
        addedPhone.quantity = 1;
        const newTotal = state.total + addedPhone.price;
        return {
          ...state,
          cart: [...state.cart, addedPhone],
          total: newTotal,
        };
      }
    case "REMOVE_FROM_CART":
      const phoneToRemove = state.cart.find((phone) => action.id === phone.id);
      const removePhone = state.cart.filter((phone) => action.id !== phone.id);

      const newTotal =
        state.total - phoneToRemove.price * phoneToRemove.quantity;
      return {
        ...state,
        cart: removePhone,
        total: newTotal,
      };
    case "DECREMENT":
      const phone = state.cart.find((phone) => action.id === phone.id);

      if (phone.quantity > 1) {
        phone.quantity -= 1;
        const newTotal = state.total - phone.price;
        return {
          ...state,
          total: newTotal,
        };
      } else {
        return state;
      }
    case "CLEAR_CART":
      return {
        ...state,
        cart: [],
        total: 0,
      };
    default:
      return state;
  }
};

export default physioReducer;
