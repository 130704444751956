import uuid from "uuid/v4";

const fashionsDefaultState = {
  fashions: [
    
    {
      id: uuid(),
      brand: "Raymond",
      name: `Casual 7`,
      price: 13,
      picture: "3",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Raymond",
      name: `Casual K20 Pro`,
      price: 350,
      picture: "4",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Raymond",
      name: `Mi 9t`,
      price: 300,
      picture: "5",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Abiba",
      name: `Dress - Formal`,
      picture: "21",
      price: 130,
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Abiba",
      name: `Sport`,
      picture: "2",
      price: 120,
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Abiba",
      name: `Galaxy A70`,
      price: 330,
      picture: "3",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Abiba",
      name: `Formal`,
      price: 650,
      picture: "5",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "bugatti",
      name: `Kids - Pro`,
      price: 760,
      picture: "6",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "bugatti",
      name: `Formal`,
      price: 580,
      picture: "7",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "bugatti",
      name: `P20 Lite`,
      price: 200,
      picture: "8",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "bugatti",
      name: `P30`,
      price: 550,
      picture: "9",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "bugatti",
      name: `Y9 Prime`,
      price: 220,
      picture: "10",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "PUMA",
      name: `Punjabi Dress`,
      price: 690,
      picture: "11",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "PUMA",
      name: `Sport X`,
      price: 1000,
      picture: "12",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "PUMA",
      name: `Formal 6s`,
      price: 500,
      picture: "13",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "PUMA",
      name: `Hand Watch`,
      price: 770,
      picture: "4",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Woodland",
      name: `Z6 Pro`,
      price: 400,
      picture: "15",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Woodland",
      name: `A6000`,
      price: 100,
      picture: "16",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "Woodland",
      name: `A7000`,
      price: 150,
      picture: "17",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "NIKE",
      name: `U19e`,
      price: 420,
      picture: "18",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
    {
      id: uuid(),
      brand: "NIKE",
      name: `10`,
      price: 300,
      picture: "19",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis eum magnam nesciunt esse nihil pariatur cupiditate fugit aut inventore minus facilis provident, nisi fuga! Dicta, saepe. Incidunt ipsum voluptatum odio",
      stats: {
        size: "6.26 inches, 97.8 cm2 ",
        resolution: "720 x 1520 pixels, 19:9 ratio",
        GPU: "Mali-G76 MP12 - EMEA",
        CPU: "Octa-core, 2x2.73 GHz Mongoose M4",
        camera: "12+ 2MP rear camera",
        battery: "1400 mAh",
      },
    },
  ],
  cart: [],
  total: 0,
};

const fashionsReducer = (state = fashionsDefaultState, action) => {
  switch (action.type) {
    case "ADD_NEW_PHONE":
      return {
        ...state,
        fashions: [...state.fashions, action.fashion],
      };
    case "ADD_TO_CART":
      const addedFasion = state.fashions.find(
        (fashion) => action.id === fashion.id
      );
      const existingFasion = state.cart.find(
        (existingFasion) => action.id === existingFasion.id
      );

      if (existingFasion) {
        addedFasion.quantity += 1;
        return {
          ...state,
          total: state.total + addedFasion.price,
        };
      } else {
        addedFasion.quantity = 1;
        const newTotal = state.total + addedFasion.price;
        return {
          ...state,
          cart: [...state.cart, addedFasion],
          total: newTotal,
        };
      }
    case "REMOVE_FROM_CART":
      const fashionToRemove = state.cart.find(
        (fashion) => action.id === fashion.id
      );
      const removeFasion = state.cart.filter(
        (fashion) => action.id !== fashion.id
      );

      const newTotal =
        state.total - fashionToRemove.price * fashionToRemove.quantity;
      return {
        ...state,
        cart: removeFasion,
        total: newTotal,
      };
    case "DECREMENT":
      const fashion = state.cart.find((fashion) => action.id === fashion.id);

      if (fashion.quantity > 1) {
        fashion.quantity -= 1;
        const newTotal = state.total - fashion.price;
        return {
          ...state,
          total: newTotal,
        };
      } else {
        return state;
      }
    case "CLEAR_CART":
      return {
        ...state,
        cart: [],
        total: 0,
      };
    default:
      return state;
  }
};

export default fashionsReducer;
