import React, { useState } from "react";
import {
  Carousel,
  render,
  Container,
  Row,
  Image,
  Button,
} from "react-bootstrap";
import imgCarousel from "../../img/films.png";
import imgCarousel1 from "../../img/films1.png";
import imgCarousel2 from "../../img/films2.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.style.css";

export const ControlledCarousel = () => {
  return (
    <div>
      <Carousel>
        <Carousel.Item>
          <div
            className="carousel-img"
            style={{
              backgroundImage: `url(${require("../../img/films.jpg")})`,
            }}
          ></div>

          <Carousel.Caption className="banner-caption">
            <h2>We Provide</h2>
            <h1>
              Flexible Packaging Films <br />
              for diverse use
            </h1>
            <div className="btn-space"></div>
            <div className="">
              <a href="#" className="btn-default btn2">
                Learn more
              </a>
            </div>
          </Carousel.Caption>

          <div className="slider-overlay"></div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            className="carousel-img"
            style={{
              backgroundImage: `url(${require("../../img/films1.png")})`,
            }}
          ></div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            className="carousel-img"
            style={{
              backgroundImage: `url(${require("../../img/films2.png")})`,
            }}
          ></div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
